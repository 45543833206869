<template>
  <div style="padding-bottom: 50px">
    <page-header
        :title="fullName" :subtitle="user ? 'User' : null"
        editable
        deletable
        @delete="deleteUserDialogOpen = true"
        @edit="editUserDialogOpen = true"
    >
      <template #title-right v-if="user">
        <div v-if="!isCovidAccount && !isPatientAccount">
          <app-chip label color="blue" v-if="isAdmin">Admin</app-chip>
          <app-chip label color="green" v-else>User</app-chip>
        </div>
        <div v-else>
          <app-chip label color="red" v-if="isCovidAccount">COVID-19 Account</app-chip>
          <app-chip label color="red" v-if="isPatientAccount">Patient Account</app-chip>
        </div>


      </template>
    </page-header>
    <!--        <v-container>-->
    <!--            <v-row>-->
    <!--                <v-col cols="12">-->
    <app-card title="User Information">
      <div class="pa-4">
        <div style="display: flex; flex-direction: row" v-for="(field, index) in fields" :key="index">
          {{ field.label }}: <strong class="pl-2">{{ field.value }}</strong>
        </div>
      </div>
    </app-card>
    <!--                </v-col>-->
    <!--                <v-col cols="12" class="mt-4">-->

    <app-card v-if="isEnterpriseView" title="User Organizations" style="overflow: inherit" class="mt-4">
      <template #header-right>
                                        <span v-if="!editingUserOrganizations">
                                                <app-fab-btn type="edit" color="white" x-small
                                                             @click="editingUserOrganizations = true"/>
                                        </span>
      </template>
      <div class="pa-4">
        <!--                            <div style="display: flex; align-items: center" class="mb-2">-->
        <!--                                <h4 class="mr-2">Groups</h4>-->
        <!--                                -->
        <!--                            </div>-->
        <div v-if="!editingUserOrganizations">
          <div style="display: flex; align-items: center">
            <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>
            <span style="color: rgba(255,0,0,.5); font-weight: 800; margin-left: 5px">IMPORTANT NOTE: </span>
          </div>
          <div style="margin-bottom: 10px">
            <strong>User: </strong>Users are required to be granted access to individual groups within the selected
            organization. Users do not have access to configure settings or add/update other users on the organization.
          </div>
          <div style="margin-bottom: 10px">
            <strong>Admin: </strong>Admins have access to all groups and profiles within the selected organization.
            Admins can configure settings and add/update other users on the organization.
          </div>
          <div v-for="organization in userOrganizations"
               :key="organization.id"
               style="border: 1px solid lightgrey; border-radius: 5px; margin: 5px 0; display: flex; align-items: center"
               class="pa-2">
            <div style="flex:1">
              {{ organization.name }}
            </div>
            <v-btn-toggle
                v-model="organization.organizationAccessTypeId"
                mandatory
                @change="updateAccountOrganization(organization.id, organization.organizationAccessTypeId)"
                :color="organization.organizationAccessTypeId == 1 ? 'green' : 'blue'"
            >
              <v-btn :value="1" style="height: 30px">
                User
              </v-btn>
              <v-btn :value="2" style="height: 30px">
                Admin
              </v-btn>
            </v-btn-toggle>
          </div>
          <div v-if="isEnterpriseView && (userOrganizations == null || userOrganizations.length < 1)">
            There are no organizations assigned to this user
          </div>

        </div>
        <div v-else>
          <multiselect v-model="editUserForm.organizations"
                       :options="organizations"
                       :searchable="true"
                       label="name"
                       :multiple="true"
                       track-by="id"
                       selectLabel=""
                       deselectLabel=""
                       :max-height="400"
                       :close-on-select="false"
          ></multiselect>
          <!--                                <AppMultipleSelect-->
          <!--                                    v-model="editUserForm.organizationIds"-->
          <!--                                    :items="organizations"-->
          <!--                                    item-text="name"-->
          <!--                                    item-value="id"-->
          <!--                                    item-title="name"-->
          <!--                                >-->
          <!--            -->
          <!--                                </AppMultipleSelect>-->
          <div class="mt-2">
            <app-btn @click="submitEditUserOrganizations" :loading="savingUserOrganizations">Save Organizations
            </app-btn>
            <app-btn outlined class="ml-2" @click="editingUserOrganizations = false">Cancel</app-btn>
          </div>
        </div>
      </div>

    </app-card>

    <app-card v-if="!isCovidAccount && !isPatientAccount" title="User Groups" class="mt-4">
      <template #header-right>
                            <span v-if="!editingUserGroups && !isAdmin">
                                    <app-fab-btn type="edit" color="white" x-small @click="editingUserGroups = true"/>
                                </span>
      </template>
      <div class="pa-4">
        <!--                            <div style="display: flex; align-items: center" class="mb-2">-->
        <!--                                <h4 class="mr-2">Groups</h4>-->
        <!--                                -->
        <!--                            </div>-->
        <div v-if="isAdmin">
          As an Admin, this user has access to all groups in the organization.
        </div>
        <div v-if="!editingUserGroups">
          <div v-for="group in userGroups" :key="group.id"
               style="border: 1px solid lightgrey; border-radius: 5px; margin: 5px 0;" class="pa-2">
            {{ group.name }}
          </div>
          <div v-if="userGroups.length === 0">
            There are no groups assigned to this user
          </div>

        </div>
        <div v-else>
          <AppMultipleSelect
              v-model="editUserForm.groupIds"
              :items="groups"
              item-text="name"
              item-value="id"
              item-title="name"
          >

          </AppMultipleSelect>
          <div class="mt-2">
            <app-btn @click="submitEditUserGroups" :loading="savingUserGroups">Save Groups</app-btn>
            <app-btn outlined class="ml-2" @click="editingUserGroups = false">Cancel</app-btn>
          </div>

        </div>

      </div>

    </app-card>
    <!--                </v-col>-->
    <!--                -->
    <!--            </v-row>-->
    <!--        </v-container>-->


    <app-confirm-dialog
        :open="deleteUserDialogOpen"
        @close="deleteUserDialogOpen = false"
        title="Delete User"
        @confirm="deleteUser"
        confirm-delete
        :loading="deletingUser"
    >
      Are you sure you want to delete user "{{ fullName }}"?
    </app-confirm-dialog>
    <dialog-form
        :open="editUserDialogOpen"
        title="Edit User"
        @close="editUserDialogOpen = false"
        @submit="submitEditUserForm"
        :width="600"
        :saving="savingUser"
    >
      <template v-slot:form-content>
        <add-user-form :new-user-form="editUserForm" :show-groups="false" :show-organizations="false"></add-user-form>
      </template>

    </dialog-form>
  </div>

</template>

<script>
import {
    getAccount,
    updateAccount,
    updateAccountModel,
    checkEmailAddress,
    deleteAccount,
    updateAccountOrganization, updateAccountGroupsModel, updateAccountGroups
} from '@/services/userService'
import {SET_SNACKBAR, SET_USER} from "@/store/mutation-types";
import AddUserForm from "@/components/UserPages/AddUserForm";
import {getGroups, getGroupsForEnterpriseAccount} from "@/services/groups.service";
import {isAdminOrgAccessType, isCovidAccount, isPatientAccount} from "@/js/shared/helper";
import {getProfessionalTypeById} from "@/js/utilities"
import {getEnterpriseOrganizations} from "@/services/enterprise.service";

export default {
    name: "User",
    components: {
        AddUserForm
    },
    data() {
        return {
            user: null,
            userGroups: [],
            groups: [],
            userOrganizations: [],
            organizations: [],
            editingUserGroups: false,
            editingUserOrganizations: false,
            deleteUserDialogOpen: false,
            deletingUser: false,
            editUserDialogOpen: false,
            savingUser: false,
            savingUserGroups: false,
            savingUserOrganizations: false,
            editUserForm: {
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                jobTitle: '',
                isSafetyCoach: false,
                professionalTypeId: null,
                organizationAccessTypeId: 1,
                groupIds: [],
                organizations: [],
                isBillingContact: false,
                optimizedSyncTimePeriodDays: 0,
                isOptimizedSyncEnabled: false
            },
        }
    },
    mounted() {
        this.initializeUser()

        this.getGroups()
        if (this.$store.state.currentAccount.isEnterpriseAdminOrg) {
            this.getOrganizations()
        }

        // 
        // this.protocol = data.protocol
    },
    watch: {
        $route(to, from) {
            if (to.params.id !== from.params.id) {
                this.clearUser()
                this.initializeUser()
            }
        },
    },

    methods: {
        async getGroups() {
            let response = null;

            if (!this.$store.state.currentAccount.isEnterpriseAdminOrg) {
                response = await getGroups();
            } else {
                response = await getGroupsForEnterpriseAccount(this.$route.params.id);
            }

            if (!response.isSuccess) {
                return
            }
            this.groups = response.groups;
        },
        async getOrganizations() {

            if (!this.$store.state.currentAccount.isEnterpriseAdminOrg)
                return;

            const response = await getEnterpriseOrganizations(true);
            if (!response.isSuccess) {
                return
            }
            this.organizations = response.organizations;
        },
        async initializeUser() {
            this.$store.commit(SET_USER, null)

            const response = await getAccount(this.$route.params.id);
            if (!response.isSuccess) {
                return
            }
            this.user = response.account;
            this.userGroups = response.groups;
            this.userOrganizations = response.organizations; //this include organizationAccessTypeId for each organization as of 10/28/2022
            this.$store.commit(SET_USER, this.user)
            this.initEditUserForm()
        },
        initEditUserForm() {
            const {
                email,
                firstName,
                lastName,
                phoneNumber,
                jobTitle,
                isSafetyCoach,
                professionalTypeId,
                selectedOrganizationAccessTypeId,
                isBillingContact,
                isOptimizedSyncEnabled,
                optimizedSyncTimePeriodDays
            } = this.user

            let organizations = [];
            let groupIds = [];

            if (this.isEnterpriseView)
                organizations = this.userOrganizations;

            groupIds = this.userGroups.map(group => group.id);


            this.editUserForm = {
                email,
                firstName,
                lastName,
                phoneNumber,
                jobTitle,
                isSafetyCoach,
                professionalTypeId,
                organizationAccessTypeId: selectedOrganizationAccessTypeId,
                groupIds,
                organizations,
                isBillingContact,
                isOptimizedSyncEnabled,
                optimizedSyncTimePeriodDays
            };


        },
        clearUser() {
            this.user = null;
            this.userGroups = null;
            this.userOrganizations = null;
        },
        async deleteUser() {
            let self = this;
            this.deletingUser = true
            const {user} = this;
            const response = await deleteAccount(user.id);
            if (!response.isSuccess) {

                this.$store.commit(SET_SNACKBAR, {
                    text: 'There was a problem deleting this user',
                    color: 'error',
                    open: true
                });
            } else {

                this.$store.commit(SET_SNACKBAR, {
                    text: 'User successfully deleted.',
                    color: 'success',
                    open: true
                });
                await self.$router.push('/users')
            }


            this.deletingUser = false
        },
        async updateAccountOrganization(organizationId, organizationAccessTypeId) {
            let request = {
                accountId: this.user.id,
                organizationId,
                organizationAccessTypeId
            }

            const response = await updateAccountOrganization(request);

            if (response.isSuccess) {
                this.$store.commit(SET_SNACKBAR, {
                    text: 'User successfully updated.',
                    color: 'success',
                    open: true
                });

                //update organizationAccessTypeId on this.userOrganizations where organizationId matches
                let index = this.userOrganizations.findIndex(x => x.organizationId === organizationId);
                if (index > -1) {
                    this.userOrganizations[index].organizationAccessTypeId = organizationAccessTypeId;
                }

                let index2 = this.editUserForm.organizations.findIndex(x => x.organizationId === organizationId);
                if (index2 > -1) {
                    this.editUserForm.organizations[index].organizationAccessTypeId = organizationAccessTypeId;
                }
            }
        },
        async submitEditUserGroups() {
            this.savingUserGroups = true;
            
            let request = {...updateAccountGroupsModel}

            request.id = this.user.id;
            request.groupIds = this.editUserForm.groupIds;
            const response = await updateAccountGroups(request);

            // 

            if (!response.isSuccess) {
                this.savingUser = false;
                return
            }

            await this.initializeUser();

            this.$store.commit(SET_SNACKBAR, {
                text: 'User Successfully Updated',
                color: 'success',
                open: true
            });

            this.savingUserGroups = false;
            this.editingUserGroups = false;

        },
        async submitEditUserOrganizations() {
            this.savingUserOrganizations = true;
            const {
                email,
                firstName,
                lastName,
                phoneNumber,
                jobTitle,
                isSafetyCoach,
                professionalTypeId,
                selectedOrganizationAccessTypeId,
                isBillingContact,
                isOptimizedSyncEnabled,
                optimizedSyncTimePeriodDays
            } = this.user;

            let request = {...updateAccountModel}

            let accountOrganizations;
            if (this.editUserForm.organizations) {
                let accountId = this.user.id;
                accountOrganizations = this.editUserForm.organizations.map(function (o) {
                    return {
                        accountId: accountId,
                        organizationId: o.id,
                        organizationAccessTypeId: o.organizationAccessTypeId
                    };
                });
            }

            request.id = this.user.id;
            request.email = email;
            request.firstName = firstName;
            request.lastName = lastName;
            request.phoneNumber = phoneNumber;
            request.jobTitle = jobTitle;
            request.isSafetyCoach = false;
            request.professionalTypeId = professionalTypeId;
            request.organizationAccessTypeId = selectedOrganizationAccessTypeId;
            request.groupIds = this.editUserForm.groupIds;
            request.accountOrganizations = accountOrganizations;
            request.updateGroups = false;
            request.updateOrganizations = true;
            request.isBillingContact = isBillingContact;
            request.isOptimizedSyncEnabled = isOptimizedSyncEnabled;
            request.optimizedSyncTimePeriodDays = optimizedSyncTimePeriodDays;
            const response = await updateAccount(request);

            // 

            if (!response.isSuccess) {
                this.savingUser = false;
                return
            }

            await this.initializeUser()
            // if (!userResponse.isSuccess) return
            //
            // const grou


            this.user = response.account;
            // this.userGroups = response.groups;

            this.$store.commit(SET_SNACKBAR, {
                text: 'User Successfully Updated',
                color: 'success',
                open: true
            });

            this.savingUserOrganizations = false;
            this.editingUserOrganizations = false;

        },
        async submitEditUserForm() {

            if (isCovidAccount() || isPatientAccount()) {
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Covid and Patient Accounts cannot be edited here. Please go to their profile page.',
                    color: 'error',
                    open: true
                });
                return;
            }

            this.savingUser = true;

            const {
                email,
                firstName,
                lastName,
                phoneNumber,
                jobTitle,
                isSafetyCoach,
                professionalTypeId,
                organizationAccessTypeId,
                groupIds,
                organizations,
                isBillingContact,
                isOptimizedSyncEnabled,
                optimizedSyncTimePeriodDays
            } = this.editUserForm;

            let request = {...updateAccountModel}

            let accountOrganizations;
            if (organizations) {
                let accountId = this.user.id;
                accountOrganizations = this.editUserForm.organizations.map(function (o) {
                    return {
                        accountId: accountId,
                        organizationId: o.id,
                        organizationAccessTypeId: o.organizationAccessTypeId
                    };
                });
            }

            request.id = this.user.id;
            request.email = email;
            request.firstName = firstName;
            request.lastName = lastName;
            request.phoneNumber = phoneNumber;
            request.jobTitle = jobTitle;
            request.isSafetyCoach = false;
            request.professionalTypeId = professionalTypeId;
            request.organizationAccessTypeId = organizationAccessTypeId;
            request.groupIds = groupIds;
            request.accountOrganizations = accountOrganizations;
            request.updateGroups = false;
            request.updateOrganizations = false;
            request.isChangingEmail = false;
            request.isBillingContact = isBillingContact;
            request.isOptimizedSyncEnabled = isOptimizedSyncEnabled;
            request.optimizedSyncTimePeriodDays = optimizedSyncTimePeriodDays;

            if (request.email !== this.user.email) {
                const response = await checkEmailAddress(request.email);
                if (!response.isSuccess) return
                // const emailAlreadyExist = response.accountExists

                if (response.accountExists) {
                    this.$store.commit(SET_SNACKBAR, {
                        text: 'Email Address Already Exists',
                        color: 'error',
                        open: true
                    })
                    this.savingUser = false;
                    return
                }

                request.isChangingEmail = true;
            }

            const response = await updateAccount(request);

            if (!response.isSuccess) {
                this.savingUser = false;
                return
            }


            this.user = response.account;
            // this.userGroups = response.groups;

            this.$store.commit(SET_SNACKBAR, {
                text: 'User Successfully Updated',
                color: 'success',
                open: true
            })


            this.savingUser = false;
            this.editUserDialogOpen = false;
        }
    },
    computed: {
        isEnterpriseView() {
            return this.$store.state.currentAccount.isEnterpriseAdminOrg;
        },
        isAdmin() {
            return isAdminOrgAccessType(this.user.selectedOrganizationAccessTypeId);
        },
        isCovidAccount() {
            return isCovidAccount(this.user.accountTypeId);
        },
        isPatientAccount() {
            return isPatientAccount(this.user.accountTypeId);
        },
        fullName() {
            return this.user ? `${this.user.firstName} ${this.user.lastName}` : 'User'
        },
        fields() {
            if (!this.user) return
            let professionalType = this.user.professionalTypeId ? getProfessionalTypeById(this.user.professionalTypeId) : '-';
            return [
                {
                    label: 'Professional Designation',
                    value: this.user.jobTitle ? this.user.jobTitle : '-'
                },
                {
                    label: 'Email',
                    value: this.user.email
                },
                {
                    label: 'Phone Number',
                    value: this.user.phoneNumber
                },
                // {
                //     label: 'Is Admin',
                //     value: this.user.isAdmin
                // },
                // {
                //     label: 'Is Safety Coach',
                //     value: this.user.isSafetyCoach ? 'Yes' : 'No'
                // },
                {
                    label: 'Medical Credentials',
                    value: professionalType
                },
                // {
                //     label: 'Access Type',
                //     value: this.user.selectedOrganizationAccessTypeId === 2 ? 'Admin' : 'User'
                // },
            ]
        }
    }
}
</script>

<style scoped>

</style>