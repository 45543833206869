<template>
    <dialog-form
        :open="open"
        @close="$emit('close')"
        :title="event ? 'Edit Event' : 'Create Event'"
        :submit-button-title="event ? 'Save Event' : 'Create Event'"
        @submit="onSubmit"
        :saving="loading"
    >
        <template #form-content>
	        <AppTextField label="Name*" v-model="form.name" rules="required"></AppTextField>
	        <app-select v-model="form.eventTypeId" label="Type" :items="eventTypeOptions" />
	        <app-form-date-picker v-model="form.startDate" label="Start Date* (LOCAL)" mask="##/##/####" placeholder="MM/DD/YYYY" rules="required" />
	        <app-form-time-picker2 v-model="form.startTime" label="Start Time* (LOCAL)" rules="required" />
	        <app-form-date-picker v-model="form.endDate" label="End Date (LOCAL)" mask="##/##/####" placeholder="MM/DD/YYYY" clearable  />
	        <app-form-time-picker2 v-model="form.endTime" label="End Time (LOCAL)" clearable/>
	        <AppTextArea label="Notes" v-model="form.note" ></AppTextArea>
            
        </template>
    </dialog-form>
</template>

<script>
import moment from "moment";
import TestTypeDot from "@/components/TestTypeDot";
import {eventTypes} from "@/js/shared/constants";
import AppFormTimePicker2 from "@/components/forms/AppFormTimePicker2";
import AppTextArea from "@/components/forms/AppTextArea.vue";

export default {
    name: "EventDialogForm",
    components: {AppTextArea, AppFormTimePicker2, TestTypeDot},
    props: {
        open: {
            type: Boolean
        },
        event: {},
	    profile: {},
        loading: {
            type: Boolean
        },
	    minEventStartDate: {},
	    
    },
    data() {
        return {
            form: null,
	        
        }
    },
    mounted() {
		this.resetForm()
        this.init();
    },
    watch: {
	    event() {
			this.init()
	    }
    },
    methods: {
        init() {
            // this.selectedTestId = this.profile.tests[0].id;
			if (this.event) {
				const {event} = this;
				this.form.name = event.name;
				this.form.eventTypeId = event.eventTypeId;
				this.form.startDate = moment(event.startedOn).format('YYYY-MM-DD');
				this.form.startTime = moment(event.startedOn).format('HH:mm');
				
				this.form.endDate = event.endedOn ? moment(event.endedOn).format('YYYY-MM-DD') : null;
				this.form.endTime = event.endedOn ? moment(event.endedOn).format('HH:mm') : null;
				this.form.note = event.note;
			} else {
				this.resetForm()
			}
            
        },
	    resetForm() {
			this.form = {
				name: '',
				eventTypeId: null,
				startDate: moment().format('YYYY-MM-DD'),
				startTime: moment().format('HH:mm'),
				note: '',
				endDate: null,
				endTime: null
			}
	    },
        onSubmit() {
			const {form, event, profile, $route} = this;
			
			let startTime = form.startTime.split(":")
			let startHour = Number(startTime[0])
			let startMinute = Number(startTime[1])
	
	        
	        
	        let endTime, endHour, endMinute;
			
			if (form.endDate) {
				endHour = moment().hour();
				endMinute = moment().minute();
				
				if (form.endTime) {
					endTime = form.endTime.split(":")
					endHour = Number(endTime[0])
					endMinute = Number(endTime[1])
				}
				
			}
			
			let request = {
				profileEvent: {
					id: event?.id ? event.id : null,
					profileId: event?.profileId ? event.profileId : profile.profileInfo.id,
					organizationId: event?.organizationId ? event.organizationId : Number($route.query.orgId),
					deletedOn: event?.deletedOn ? event.deletedOn : null,
					
					name: form.name,
					note: form.note,
					eventTypeId: form.eventTypeId,
					startedOn: moment.utc(moment(form.startDate).hour(startHour).minute(startMinute)).format(),
					endedOn: form.endDate ? moment.utc(moment(form.endDate).hour(endHour).minute(endMinute)).format() : null
				}
			}
			
			this.$emit("submit", request)
        },
	    
    },
    computed: {
	    today() {
		    return moment().format('YYYY-MM-DD')
	    },
	    maxEndTime() {
			return moment().format("HH:mm")	
	    },
	    eventTypeOptions() {
		    return eventTypes.map(event => ({
				text: event.name,
			    value: event.id
		    }))
	    }
    }
}
</script>

<style scoped>
.test-card {
    border: 1px solid var(--v-border-base); 
    margin: 5px 0; 
    border-radius: 5px; 
    padding: 10px;
    display: flex;
    align-items: center;
}

.test-card:hover {
    cursor: pointer;
}

.selected-test-card {
    background-color: rgba(130,203,195,.2);
    border: 1px solid rgba(130,203,195,1)
}

.dot {
    height: 8px; 
    width: 8px; 
    border-radius: 5px; 
    background-color: red; 
    margin-right: 10px;
}

.baseline {
    background-color: white;
    border: 2px solid var(--v-primary-base)
}

.event {
    background-color: var(--v-event-base);
    border: 2px solid var(--v-event-base)
}

.excluded {
    background-color: var(--v-charcoal-base);
    border: 2px solid var(--v-charcoal-base)
}

</style>