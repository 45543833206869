import { render, staticRenderFns } from "./EventDialogForm.vue?vue&type=template&id=78eb235c&scoped=true"
import script from "./EventDialogForm.vue?vue&type=script&lang=js"
export * from "./EventDialogForm.vue?vue&type=script&lang=js"
import style0 from "./EventDialogForm.vue?vue&type=style&index=0&id=78eb235c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78eb235c",
  null
  
)

export default component.exports