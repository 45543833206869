<template>
    <div style="min-height: 90%">
        <div
            v-if="eventStartDateDisplay"
            
            class="event-banner">
            <div style="flex: 1"/>
            <div style="flex: 1; text-align: center; ">
                Event Started {{ eventStartDateDisplay }}
            </div>
	        <div style="flex: 1; margin-right: 5px; display: flex; justify-content: flex-end">
		        <app-btn style="" small color="white" class="mr-1" @click="editCurrentEvent">
			        <v-icon class="mr-1" small>mdi-pencil</v-icon>
			        Edit Event
		        </app-btn>
<!--		        <app-btn style="" small color="white" @click="endEventDialogOpen = true">-->
<!--			        End Event-->
<!--		        </app-btn>-->
	        </div>
            

        </div>
        <page-header
            :title="pageTitle"
            subtitle="Profile"
            :editable="!!profile"
            :deletable="!!profile"
            :shareable="!!profile && organizationPreferences.isSwayVisaEnabled"
            @edit="editProfileDialogOpen = true"
            @delete="deleteProfileDialogOpen = true"
            @share="shareSwayVisa"
            :menu="!!profile"
        >
            <template #below>
                <div v-if="simpleProfile" style="transform: translateY(-10px); color: var(--v-text-lighten2); font-size: 13px">
                    Profile ID: {{ simpleProfile.id }}
                </div>
                <div v-if="simpleProfile && organizationPreferences.externalApiEnabled"
                     style="transform: translateY(-10px); color: var(--v-text-lighten2); font-size: 13px">
                    API Unique ID: {{ simpleProfile.uniqueId }}
                </div>
                <div v-if="simpleProfile && organizationPreferences.externalApiEnabled"
                     style="transform: translateY(-10px); color: var(--v-text-lighten2); font-size: 13px">
                    API External ID: {{ simpleProfile.externalId }}
                </div>
            </template>
            <template #title-right v-if="profile">
                <div style="display: flex">

                    <!--                    <div v-if="profile.data.eventStartedOn" class="ml-2">-->
                    <!--                        <app-chip-->
                    <!--                            color="warning"-->
                    <!--                            dark-->
                    <!--                        >-->
                    <!--&lt;!&ndash;                            <span class="mr-1"><v-icon>mdi-alert</v-icon></span>&ndash;&gt;-->
                    <!--                            Currently in Event-->
                    <!--                        </app-chip>-->
                    <!--                    </div>-->
                    <div v-if="profileIsInactive" class="ml-2">
                        <app-chip color="red" label>Inactive</app-chip>
                    </div>
                    <div v-if="pdfSyncStatusObject" class="ml-2">
                        <app-chip :color="pdfSyncStatusObject.chipColor" label>
                            Pdf Upload {{pdfSyncStatusObject.displayText}}
                        </app-chip>
                    </div>
                </div>


            </template>
            <template #title-right v-else-if="profile && profile.demandStatus">
                
                
                <span style="display: flex" class="ml-4" v-if="profile">  
                    <span v-if="profile.demandStatus.cognitive.value">
                        <app-chip

                            dark
                            :style="{'background-color': getColor(profile.demandStatus.cognitive.value)}"

                        >
                            <span class="mr-1">
<!--                                <v-icon>mdi-alert</v-icon>-->
                            </span>
                            Cognitive: {{ profile.demandStatus.cognitive.text }}
                        </app-chip>
                    
                    </span>
                    <span v-if="profile.demandStatus.covid.value" class="ml-4">
                        <app-chip

                            dark
                            style="background-color: red"

                        >
                            <span class="mr-1">
<!--                                <v-icon>mdi-alert</v-icon>-->
                            </span>
                            Covid: {{ profile.demandStatus.cognitive.text }}
                        </app-chip>
                    
                </span>
            </span>


            </template>
            <div slot="right-content" v-if="profile">
                <app-btn
                    @click="createReportDialog = true"
                >
                    <v-icon class="mr-2">mdi-printer</v-icon>
                    Create Report
                </app-btn>
            </div>

            <template #menu-content>
                <div style="min-width: 200px">
                    <div class="pa-2"
                         style="text-transform: uppercase; background-color: white; font-size: .8em; color: #bdbdbd; font-weight: 500"
                    >Profile Actions
                    </div>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in menuItems"
                            :key="index"
                            class="pl-2 pr-2"
                            link
                            @click="item.onClick"
                            dense
                        >
                            <v-list-item-title>{{ item.label }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>
            </template>
        </page-header>
        <!--        <app-btn @click="adjustBaselineDialogOpen = true">Adjust Calculations</app-btn>-->


        <div v-if="loadingWebDetails"
             style="width: 100%; min-height: 400px; display: flex; justify-content: center; align-items: center; flex-direction: column">
            <!--            <v-progress-circular-->
            <!--                indeterminate-->
            <!--                color="primary"-->
            <!--                :size="100"-->
            <!--            ></v-progress-circular>-->
            <!--            <span class="mt-4">-->
            <!--                Loading Profile-->
            <!--            </span>-->
            <app-page-loader :text="recalculatingBaseline ? 'Recalculating Baseline...' : 'Loading Profile...'"></app-page-loader>

        </div>


        <div v-else>
            <app-tabs :value="currentTab" class="pb-1">
                <app-tab
                    v-for="(tab, index) in tabs"
                    :key="index"
                    @click="$router.push(tab.route)"
                    :icon="tab.icon"
                >{{ tab.label }}
                </app-tab>
                <!--                <app-tab @click="$router.push(`/profiles/${simpleProfile.id}/baselines`)" icon="mdi-chart-timeline">Baselines</app-tab>-->
                <!--                <app-tab @click="$router.push(`/profiles/${simpleProfile.id}/trends`)" icon="mdi-chart-timeline-variant">Trends</app-tab>-->
                <!--                <app-tab @click="$router.push(`/profiles/${simpleProfile.id}/test-history`)" icon="mdi-table">Test History</app-tab>-->
                <!--                <app-tab @click="$router.push(`/profiles/${simpleProfile.id}/groups`)" icon="mdi-account-group">Groups</app-tab>-->
                <!--                <app-tab @click="$router.push(`/profiles/${simpleProfile.id}/sessions`)" icon="mdi-account-multiple-plus">Sessions</app-tab>-->
                <!--                <app-tab @click="$router.push(`/profiles/${simpleProfile.id}/calendar`)" icon="mdi-calendar">Calendar</app-tab>-->
                <!--                <app-tab>Settings</app-tab>-->
            </app-tabs>


            <!--            <ProfileOverview -->
            <!--                :profile="profile"-->
            <!--                :loadingWebDetails="loadingWebDetails"-->
            <!--                :chartOptions="chartOptions"-->
            <!--                v-if="detailedProfileLoaded"-->
            <!--                :simple-profile="simpleProfile"-->
            <!--            />-->
            <router-view
                :profile="profile"
                :loadingWebDetails="loadingWebDetails"
                :chartOptions="chartOptions"
                v-if="detailedProfileLoaded"
                :simple-profile="simpleProfile"
                :profileGroups="profileGroups"
                :organizationGroups="organizationGroups"
                :updateProfileModel="updateProfileModel"
                :editGroupsForm="editGroupsForm"
                @updateGroups="updateGroups"
                @createSession="createSessionDialogOpen = true"
                @createEvent="createEvent"
                :savingProfile="savingProfile"
                :profileIsInactive="profileIsInactive"
                @testUpdated="testUpdated"
                @editEvent="editEvent"
            />


        </div>
        <app-confirm-dialog
            :open="deleteProfileDialogOpen"
            title="Delete Profile"
            :loading="deletingProfile"
            @confirm="deleteProfile"
            @close="deleteProfileDialogOpen = false"
            confirm-delete
        >
            <div>Are you sure you want to delete the profile for {{ firstName }} {{ lastName }}?</div>
        </app-confirm-dialog>
        <dialog-form
            :open="editProfileDialogOpen"
            title="Edit Profile"
            @close="editProfileDialogOpen = false"
            @submit="updateProfile"
            :width="600"
            :saving="savingProfile"
        >
            <template v-slot:form-content>
                <ProfileCreateUpdateForm
                    :form="editProfileForm"
                    :organizationPreferences="organizationPreferences"
                    :protocols="protocols"
                    :profileAccessLinks="profileAccessLinks"
                />
            </template>
        </dialog-form>

        <dialog-form
            :open="endEventDialogOpen"
            title="End Event"
            @close="endEventDialogOpen = false"
            @submit="endEvent"

            :width="600"
            :saving="savingEndEvent"
            submit-button-title="Submit"

        >
            <template v-slot:form-content>
                Would you like to end the event for this profile?
                <app-form-date-picker
                    label="Event End Date"
                    v-model="endEventDate"
                    class="mt-2"
                    rules="required"
                    :allowed-dates="e => endEventAllowedDates(e)"
                />
            </template>
        </dialog-form>

        <dialog-form
            :open="adjustBaselineDialogOpen"
            title="Adjust Baseline Calculation"
            :width="600"
            @close="adjustBaselineDialogOpen = false"
            :saving="savingBaselineStartDate"
            @submit="submitAdjustBaselineCalculation"
        >
            <template #form-content>
                <v-radio-group v-model="adjustBaselineCalculationType">
                    <v-radio v-for="item in adjustBaselineStartDateRadioOptions" :key="item.value" :label="item.label" :value="item.value"></v-radio>
                </v-radio-group>
                <div v-if="adjustBaselineCalculationType === 1">
                    <app-form-date-picker

                        label="Start Date"
                        v-model="adjustBaselineStartDate"
                        class="mt-2"
                    />
                    <app-form-time-picker
                        label="Start Time"
                        v-model="adjustBaselineStartTime"
                        class="mt-2"
                    />
                </div>

            </template>
        </dialog-form>
        <dialog-form
            :open="profileAccessDialogOpen"
            title="Share Profile"
            width="90%"
            max-width="600"
            @close="profileAccessDialogOpen = false"
            :saving="creatingProfileAccessLink"
            @submit="createProfileAccessLink"
            submit-button-title="Generate New Link"
        >
            <template #form-content>

                <SwayVisaCard :profile-access-link-url="profileAccessLink" :profile="simpleProfile"></SwayVisaCard>

                <div style="color: #757575">
                    <span>Share the link or QR code below with a Parent, Guardian, Individual, or a Sway Administrator. With access to their personal profile, they will have these convenient features:</span>
                    <ul>
                        <li>Simplified re-baselining without re-entering profile data.</li>
                        <li>Conveniently update personal information (height, weight, etc.).</li>
                        <li>Comfortably view and complete remotely assigned tests with ease.</li>
                    </ul>
                    <br>

                    <div style="display: flex; align-items: center">
                        <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>
                        <span style="color: rgba(255,0,0,.5); font-weight: 800; margin-left: 5px">IMPORTANT NOTE: </span>
                    </div>

                    <div style="margin-bottom: 10px">
                        This link can only be used ONCE and will expire in 24 hours. If you need to share this profile with more than one person, you will
                        need to generate a new link.
                    </div>
                </div>
                
            </template>
        </dialog-form>
        <CreateReportDialogForm
            :open="createReportDialog"
            @close="createReportDialog = false"
            :profile="profile"
            @createReport="createReport($event)"
            :loading="creatingReport"
        />
	    <EventDialogForm 
	        :open="eventDialogOpen"
	        @close="eventDialogOpen = false"
	        :event="eventForEditEventForm"
	        @submit="createOrUpdateEvent"
	        :loading="savingEvent"
	        :profile="profile"
	        :minEventStartDate="minEventStartDate"
	        :maxEventEndDate="maxEventEndDate"
	    />
        <SessionCreateForm
            v-if="simpleProfile"
            :open="createSessionDialogOpen"
            @close="createSessionDialogOpen = false"
            :profile="simpleProfile"
            :profiles="[simpleProfile]"
            :organizationPreferences="organizationPreferences"
        />

<!--                        <div class="pt-2" v-show="true" v-if="profileForPdf && profileTest">-->
<!--                            <pdf-export-->
<!--                                :simple-profile="simpleProfile"-->
<!--                                :profileTest="profileTest"-->
<!--                                :profile="profileForPdf"-->
<!--                                :enableSportsProfileProperties="organizationPreferences.enableSportsProfileProperties"-->
<!--                            ></pdf-export>-->
<!--                        </div>-->

    </div>
</template>

<script>
import SwayProfile from "@/js/shared/classes/Profile/SwayProfile";
import {getGroups} from '@/services/groups.service';
import QrcodeVue from 'qrcode.vue'

import moment from 'moment'
import 'moment-timezone'
import {
    adjustBaselineCalculationDate,
    createProfileEvent,
    deleteProfile,
    endCurrentProfileEvent,
    generateProfileAccessLink,
    getAccountProfilesByProfileId,
    getPdfSyncStatus, retryPdfSync, updatePdfSyncStatus,
    getProfile,
    getWebProfileDetail,
    profileTestPdfForPortal,
    updateProfile,
    updateProfileEvent,
    updateProfileModel,
} from '@/services/profile.service'
import PdfExport from '@/components/pdf-export/index'
import {SET_PROFILE, SET_SNACKBAR} from "@/store/mutation-types";
import ProfileOverview from "@/components/Profile/ProfileOverview";
import {getOrganizationPreferences} from "@/services/organization.service";
import ProfileCreateUpdateForm from "@/components/Profile/ProfileCreateUpdateForm";
import SessionCreateForm from "@/components/Sessions/SessionCreateForm";
import ProfileTestPdfExportMixin from "@/mixins/profile-test-pdf-export";
import CreateReportDialogForm from "@/components/Profile/CreateReportDialogForm";
import {getProtocols} from "@/services/protocol.service";
import {pdfSyncStatusOptions} from "@/js/shared/constants";
import {guessCurrentTimezone, asLocalTime} from "@/js/shared/helper";
import SwayVisaCard from "@/components/SwayVisa/SwayVisaCard.vue";
import EventDialogForm from "@/components/Profile/EventDialogForm";
import {getProfileAccessLinksByProfileId} from "../services/profile.service";

// import {Chart} from 'highcharts-vue'

export default {
    name: "Profile",
    components: {
	    EventDialogForm,
        SwayVisaCard,
        CreateReportDialogForm,
        SessionCreateForm,
        ProfileCreateUpdateForm,
        PdfExport,
        ProfileOverview,
        QrcodeVue,
        // highcharts: Chart
    },
    mixins: [ProfileTestPdfExportMixin],
    data() {
        return {
            endEventDate: null,
            createSessionDialogOpen: false,
            
            createReportDialog: false,
            detailedProfileLoaded: false,
            deleteProfileDialogOpen: false,
            editProfileDialogOpen: false,
	        
	        
	        eventDialogOpen: false,
	        endEventDialogOpen: false,
	        editEventId: null,
	        
            deletingProfile: false,

            savingProfile: false,
            savingEndEvent: false,
	        savingEvent: false,

            rawProfile: null,
            profile: null,
            simpleProfile: null,

            profileGroups: [],
            organizationGroups: [],

            profileForPdf: null,
            profileTestForPdf: null,
            creatingReport: false,

            chartOptions: null,
            loadingWebDetails: false,

            organizationPreferences: null,
            headers: [
                {
                    text: 'Date',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {text: 'Balance', value: 'balance'},
                {text: 'Reaction Time', value: 'balance'},
                {text: 'Impulse Control', value: 'balance'},
                {text: 'Inspection Time', value: 'balance'},
            ],
            editProfileForm: null,
            editGroupsForm: null,
            updateProfileModel: {},

            profileAccessDialogOpen: false,
            creatingProfileAccessLink: false,
            profileAccessLink: null,
            qrCodeSize: 300,

            adjustBaselineDialogOpen: false,
            adjustBaselineStartDate: moment().format('YYYY-MM-DD'),
            savingBaselineStartDate: false,
            recalculatingBaseline: false,
            adjustBaselineCalculationType: 0,
            adjustBaselineStartTime: {
                hour: 6,
                minute: 0,
                amPm: 'am'
            },
            adjustBaselineStartDateRadioOptions: [
                {
                    label: "Use all tests",
                    value: 0,
                },
                {
                    label: "Use tests starting from date",
                    value: 1
                }
            ],

            protocols: [],
            profileAccessLinks: [],

            pdfSyncStatus: null,
            pdfSyncStatusOptions: pdfSyncStatusOptions

        }
    },

    watch: {
        $route(to, from) {
            if (to.params.profileId !== from.params.profileId) {
                // this.clearProfile()
                // this.initializeProfile()
                // this.initSimpleProfile()
                this.init()
            }
        },
    },
    async mounted() {
        // alert(this.$route.params.profileId)
        // const {profile} = await getProfile(this.$route.params.profileId);
        await this.init()

    },
    computed: {
		menuItems() {
            
			let items = [
				{
					label: 'Adjust Baseline Start Date',
					onClick: () => this.adjustBaselineDialogOpen = true
				},
				{
					label: 'Edit Profile Groups',
					onClick: () => this.$router.push(`/profiles/${this.simpleProfile.id}/groups`)
				},
				{
					label: 'Create Code',
					onClick: this.createCode
				},
				{
					label: 'Share Profile',
					onClick: this.shareSwayVisa
				},
			]
			
			if (!this.eventStartDateDisplay) {
				items.push({
					label: 'Create Event',
					onClick: this.createEvent
				})
			}

            if (this.pdfSyncStatus && this.pdfSyncStatusObject && this.pdfSyncStatusObject.chipColor === 'red') {
                items.push({
                    label: 'Mark Pdf As Manually Uploaded',
                    onClick: this.markPdfSyncManual
                });
                items.push({
                    label: 'Abandon Pdf Sync',
                    onClick: this.markPdfSyncDeleted
                });
            }
			
			return items
		},
        currentTestId() {
            if (this.$route.query.testId) {
                return parseInt(this.$route.query.testId)
            } else {
                return this.profile.tests[0].id
            }

        },
        eventStartDateDisplay() {
            if (!this.profile?.data?.eventStartedOn) return null

            return asLocalTime(moment.utc(this.profile.data.eventStartedOn)).format('M/D/YYYY')

        },
	    eventForEditEventForm() {
			return this.profile?.events?.find(event => event.id === this.editEventId)
			//
			// if (!this.profile?.data?.eventStartedOn) return null
		    // return this.profile.events[0]
	    },
	    minEventStartDate() {
		
			if (!this.profile || !this.profile.events) return null
			
		    // If its a new event being created

				if (this.profile.events.length < 1) {
					return null // the first event on a profile doesn't need any constraints
				} 

			else {
				let currentEventIndex = this.profile.events.findIndex(event => event.id === this.editEventId);
				if (currentEventIndex < 1) {
					return null
				} else {
					return this.profile.events[currentEventIndex - 1].endedOn
				}
			}
	    },
	    maxEventEndDate() {
		    if (!this.profile || !this.profile.events) return null
			
		    // If its a new event being created
		    if (!this.editEventId) {
			    return null
		    }
		    // if its an existing event being edited
		    else {
			    let currentEventIndex = this.profile.events.findIndex(event => event.id === this.editEventId);
			    if (currentEventIndex === this.profile.events.length - 1) {
				    return null
			    } else {
				    return this.profile.events[currentEventIndex + 1].startedOn
			    }
		    }
	    },
        profileTest() {
            if (!this.profile || this.profile.tests.length === 0) return null
            return this.currentTestId ? this.profile.tests.find(test => test.id === this.currentTestId) : this.profile.tests[0]
        },
        profileIsInactive() {
            let isActive = false
            let {organizationGroups, profileGroups} = this;
            if (!profileGroups.length > 0 && !organizationGroups.length > 0) return null
            profileGroups.forEach(groupId => {
                let orgGroup = organizationGroups.find(x => x.id === groupId)
                if (orgGroup && orgGroup.isEnabled) isActive = true
            })
            return !isActive
        },
        currentProduct() {
            return this.$store.state.currentProduct
        },
        pageTitle() {
            if (!this.simpleProfile) return 'Profile'
            if (this.$store.state.currentAccount.viewingAsSuper) return `Profile ID: ${this.simpleProfile.id}`
            return `${this.firstName} ${this.lastName}`
        },
        firstName() {
            return this.simpleProfile ? this.simpleProfile.firstName : null
        },
        lastName() {
            return this.simpleProfile ? this.simpleProfile.lastName : null
        },
        profileHasTests() {
            return this.profile?.tests?.length > 0
        },
        currentTab() {
            let items = this.$route.path.split('/')
            let last = items.pop();
            if (this.profileHasTests) {
                switch (last) {
                    case 'overview':
                        return 0;
	                case 'test-history':
		                return 1;
	                case 'trends':
		                return 2;
                    case 'baselines':
                        return 3;
	                case 'events':
		                return 4;
                    
                    case 'groups':
                        return 5;
                    case 'codes':
                        return 6
                }
            }

            switch (last) {
                case 'overview':
                    return 0
                case 'groups':
                    return 1;
                case 'codes':
                    return 2;
            }


        },
        tabs() {
            if (!this.profile) return null;
            const {id} = this.simpleProfile;


            if (this.profileHasTests) {
                return [
                    {
                        label: 'Overview',
                        route: `/profiles/${id}/overview`,
                        icon: 'mdi-view-dashboard-outline'
                    },
	                {
		                label: 'Test History',
		                route: `/profiles/${id}/test-history`,
		                icon: 'mdi-table'
	                },
	                {
		                label: 'Trends',
		                route: `/profiles/${id}/trends`,
		                icon: 'mdi-chart-timeline-variant'
	                },
                    {
                        label: 'Baselines',
                        route: `/profiles/${id}/baselines`,
                        icon: 'mdi-chart-timeline'
                    },
	                {
		                label: 'Events',
		                route: `/profiles/${id}/events`,
		                icon: 'mdi-calendar-alert-outline'
	                },
                    
                    {
                        label: 'Groups',
                        route: `/profiles/${id}/groups`,
                        icon: 'mdi-account-group'
                    },
                    {
                        label: 'Codes',
                        route: `/profiles/${id}/codes`,
                        icon: 'mdi-account-multiple-plus'
                    },

                ]
            }

            return [
                {
                    label: 'Overview',
                    route: `/profiles/${id}/overview`,
                    icon: 'mdi-view-dashboard-outline'
                },
                {
                    label: 'Groups',
                    route: `/profiles/${id}/groups`,
                    icon: 'mdi-account-group'
                },
                {
                    label: 'Codes',
                    route: `/profiles/${id}/codes`,
                    icon: 'mdi-account-multiple-plus'
                },

            ]

        },
        pdfSyncStatusObject() {
            return this.pdfSyncStatusOptions.find(x => x.value === this.pdfSyncStatus?.intentStatusId)
        }
    },
    methods: {
        async init() {

            this.clearProfile()
            await this.initSimpleProfile()
            await this.initializeProfile()

            await this.getGroups();
            await this.getProtocols();
            this.profileAccessLinks = await this.getProfileAccessLinks();
            let response = await getPdfSyncStatus(this.simpleProfile.id);
            if (response && response.pdfSyncStatuses)
            {
                let allPdfSyncStatuses = response.pdfSyncStatuses;
                this.pdfSyncStatus = allPdfSyncStatuses.length > 0 ? allPdfSyncStatuses[0] : null
            }
        },
        async endEvent() {
            this.savingEndEvent = true;

            let request = {
                profileId: this.profile.profileInfo.id,
                eventEndedDateTimeUtc: moment.utc(this.endEventDate).format()
            }

            let response = await endCurrentProfileEvent(request)

            if (!response.isSuccess) {
                this.$store.commit(SET_SNACKBAR, {
                    text: `Failed to end profile's event.`,
                    color: 'error',
                    open: true
                });
            } else {
                this.$store.commit(SET_SNACKBAR, {
                    text: `Successfully ended profile's event.`,
                    color: 'success',
                    open: true
                });
            }

            this.savingEndEvent = false;
            this.endEventDialogOpen = false
            this.init()

        },
        endEventAllowedDates(e, index) {
            let isAllowed = moment(e).isBefore(moment());
            return isAllowed
        },
	    createEvent() {
		    this.editEventId = null;
		    this.eventDialogOpen = true;
	    },
	    editEvent(event) {
		    this.editEventId = event.id;
		    this.eventDialogOpen = true;
			
	    },
	    editCurrentEvent() {
			this.editEventId = this.profile?.events[0]?.id ? this.profile.events[0].id : null;
		    this.eventDialogOpen = true;
	    },
	    async createOrUpdateEvent(request) {
			this.savingEvent = true;
		    
			let isNewEvent = !request.profileEvent.id; // check to see if we're updating an existing event or creating a new event
		    
		    let response;
			if (isNewEvent) {
				request.profileEvent.id = 0
				response = await createProfileEvent(request)
			} else {
				response = await updateProfileEvent(request);
			}
		
		    if (!response.isSuccess) {
			    this.$store.commit(SET_SNACKBAR, {
				    text: response.errors[0].message,
				    color: 'error',
				    open: true
			    });
			    this.savingEvent = false;
			    return
		    }
		    
		    this.eventDialogOpen = false;
		    this.savingEvent = false;
		    this.$store.commit(SET_SNACKBAR, {
			    text: isNewEvent ? 'Event Successfully Created' : 'Event Successfully Updated',
			    color: 'success',
			    open: true
		    });
			
		    this.init()

	    },
        createCode() {
            if (this.profileIsInactive) {
                return this.$store.commit(SET_SNACKBAR, {
                    text: 'Cannot create code for an inactive profile.',
                    color: 'error',
                    open: true
                });
            }
            this.createSessionDialogOpen = true
        },
        shareSwayVisa() {
            if (!this.organizationPreferences.isSwayVisaEnabled) {
                alert("Please contact Sway Support for information on enabling Profile Sharing.")
                return;
            }
            
            //create profile access link when user clicks on share
            this.createProfileAccessLink();

            this.profileAccessDialogOpen = true
        },
        async getProtocols() {
            const response = await getProtocols();
            this.protocols = response.protocols
        },
        async createReport(event) {
            this.creatingReport = true;
            let authToken = localStorage.getItem('jwtToken');

            const {selectedTestId, includedTestIds, pdfPassword} = event;

            let profileTestId = selectedTestId;
            await profileTestPdfForPortal({
                id: selectedTestId,
                includedTestIds: includedTestIds.join(),
                password: pdfPassword,
                timezone: guessCurrentTimezone(),
                enableSportsProfileProperties: this.organizationPreferences.enableSportsProfileProperties
            })
            this.creatingReport = false;
            this.createReportDialog = false
            // let routeData = this.$router.resolve({path: 'http://localhost:8080/profile-test-fullpage', query: {profileTestId, authToken, includedTestIds: includedTestIds.join()}});
            // 
            // window.open(routeData.href, '_blank');

            // let profileForPdf = JSON.parse(JSON.stringify(this.rawProfile))
            //
            //
            // this.profileTestForPdf = this.profile.tests.find(test => test.id === selectedTestId);
            //
            // let newTestsForProfile = [];
            // profileForPdf.tests.forEach(test => {
            //     if (includedTestIds.includes(test.id)) {
            //         newTestsForProfile.push(test)
            //     }
            // })
            //
            // profileForPdf.tests = newTestsForProfile
            //
            // this.profileForPdf = new SwayProfile(profileForPdf)
            //
            // setTimeout(async () => {
            //     // give the page some time to update the pdf
            //    
            //     await this.exportPdf({pdfPassword});
            //     this.creatingReport = false;
            //     this.createReportDialog = false
            // }, 200)

        },
        async getOrganizationPreferences() {
            const response = await getOrganizationPreferences();
            if (!response.isSuccess) return;

            const {organizationPreferences} = response;
            this.organizationPreferences = organizationPreferences;
        },
        async submitAdjustBaselineCalculation() {
            this.savingBaselineStartDate = true;

            const {adjustBaselineStartDate} = this;

            let {hour, minute, amPm} = this.adjustBaselineStartTime;
            hour = amPm.toLowerCase() === 'pm' ? hour + 12 : hour;

            let adjustBaselineStartDateCentral = '2000-01-01T00:00:00';
            if (this.adjustBaselineCalculationType !== 0) {
                let date = asLocalTime(moment(adjustBaselineStartDate));
                adjustBaselineStartDateCentral = date.hour(hour).minute(minute).format();
            }
            let adjustBaselineStartDateToSubmit = moment.utc(adjustBaselineStartDateCentral).format();

            // let adjustBaselineStartDateToSubmit = this.adjustBaselineCalculationType === 0 ? '2000-01-01T00:00:00' : moment(adjustBaselineStartDate).format()


            const request = {
                profileId: this.simpleProfile.id,
                baselineStartDate: adjustBaselineStartDateToSubmit
            };

            const response = await adjustBaselineCalculationDate(request);
            if (!response.isSuccess) {
                this.savingBaselineStartDate = false;
                return
            }

            this.savingBaselineStartDate = false;
            this.adjustBaselineDialogOpen = false;

            this.loadingWebDetails = true;
            this.recalculatingBaseline = true;
            await this.initializeProfile()
            this.recalculatingBaseline = false;
        },
        async createProfileAccessLink() {
            this.creatingProfileAccessLink = true;

            const response = await generateProfileAccessLink(this.simpleProfile.id, "");
            if (!response.isSuccess) {
                this.creatingProfileAccessLink = false;
                return
            }

            this.profileAccessLink = response.link;

            this.creatingProfileAccessLink = false;
        },
        getColor(value) {
            switch (value) {
                case 1:
                    return 'salmon';
                case 2:
                    return 'orange';
                case 3:
                    return 'skyblue';
                case 4:
                    return 'limegreen'


            }
        },
        clearProfile() {
            this.profile = null;
            this.simpleProfile = null;
            this.chartOptions = null;
        },
        async getGroups() {
            const response = await getGroups()
            this.organizationGroups = response.groups;
        },
        async getAccountProfiles() {
            let response = await getAccountProfilesByProfileId(this.$route.params.profileId);
            return response.accountProfiles;
        },
        async getProfileAccessLinks() {
            let response = await getProfileAccessLinksByProfileId(this.$route.params.profileId);

            let linksWithEmails = response.profileAccessLinks.filter(profileAccessLink => profileAccessLink.sentToEmail !== null && profileAccessLink.sentToEmail !== "")
            let linksNotClaimed = linksWithEmails.filter(profileAccessLink => !profileAccessLink.claimedByAccountId)
            
            return linksNotClaimed;
        },
        async initSimpleProfile() {
            await this.getOrganizationPreferences()

            const response = await getProfile(this.$route.params.profileId);
            if (!response.isSuccess) {
                return
            }

            const {profile} = response;
            this.simpleProfile = profile;
            this.profileGroups = profile.groupIds;
            await this.setEditProfileForm();
            this.setUpdateProfileModel()
        },
        async initializeProfile() {
            this.loadingWebDetails = true;
            this.$store.commit(SET_PROFILE, null)

            const response = await getWebProfileDetail(this.$route.params.profileId);
            // 
            if (!response.isSuccess) {
                return
            }
            const {profile} = response;


            this.profile = new SwayProfile(profile);
            this.rawProfile = profile;

            const {baselineStartDateMoment} = this.profile.profileInfo
            let year = baselineStartDateMoment.year();
            if (year > 2001) {
                this.adjustBaselineStartDate = baselineStartDateMoment.format('YYYY-MM-DD');
                this.adjustBaselineStartTime = {
                    hour: baselineStartDateMoment.hour() >= 12 ? baselineStartDateMoment.hour() - 12 : baselineStartDateMoment.hour(),
                    minute: baselineStartDateMoment.minute(),
                    amPm: baselineStartDateMoment.hour() >= 12 ? 'pm' : 'am'
                };
                this.adjustBaselineCalculationType = 1
            }


            this.profileForPdf = this.profile;
            // this.profileTestForPdf = this.profile.tests[0]

            this.detailedProfileLoaded = true;
            this.$store.commit(SET_PROFILE, this.profile);


            // const x = this.profile;
            // 


            // 


            this.loadingWebDetails = false;
        },
        async setEditProfileForm() {

            let form = SwayProfile.getProfileForm({
                isNewProfile: false,
                profile: this.simpleProfile,
                isMetric: this.organizationPreferences.isMetric,
                createdInGroupId: null,
                isMobile: false
            });

            form.accountProfiles = await this.getAccountProfiles();

            this.editProfileForm = form;
            this.editGroupsForm = form;
        },
        setUpdateProfileModel() {


            let model = {...updateProfileModel}
            const {
                firstName,
                lastName,
                height,
                weight,
                gender,
                birthDate,
                email,
                id,
                graduationYear,
                additionalData
            } = this.simpleProfile;

            // 

            model.id = id;
            model.firstName = firstName;
            model.lastName = lastName;
            model.height = height;
            model.weight = weight;
            model.birthDate = birthDate;
            model.gender = gender;
            model.email = email;
            model.groupIds = this.profileGroups.map(group => {
                return group.id
            });
            model.baselineStartDate = this.simpleProfile.baselineStartDate;
            model.defaultOrganizationProtocolId = this.simpleProfile.defaultOrganizationProtocolId;
            model.graduationYear = graduationYear;

            if (model.additionalData){
                const {
                    hasHadConcussion,
                    lastConcussionDate,
                    hasAttentionDisorder,
                    race,
                    primaryLanguage,
                    secondaryLanguage,
                    genderIdentity
                } = additionalData
    
                model.additionalData = {
                    hasHadConcussion,
                    lastConcussionDate,
                    hasAttentionDisorder,
                    race,
                    primaryLanguage,
                    secondaryLanguage,
                    genderIdentity
                }
            }


            this.updateProfileModel = model
        },
        updateGroups() {
            this.submitUpdateProfileForm({form: this.editGroupsForm, updateGroups: true})
        },
        async copyProfileAccessLink() {
            try {
                await navigator.clipboard.writeText(this.profileAccessLink)
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Link Copied to Clipboard',
                    color: 'success',
                    open: true
                });
            } catch ($e) {
            }
        },
        updateProfile() {
            const {editProfileForm} = this;
            this.submitUpdateProfileForm({form: editProfileForm, updateGroups: false})
        },
        async submitUpdateProfileForm({form, updateGroups}) {
            this.savingProfile = true;

            const request = SwayProfile.getCreateUpdateProfileRequestFromForm({
                form: form,
                isMetric: this.organizationPreferences.isMetric,
                isMobile: false,
                enableSportsProfileProperties: this.organizationPreferences.enableSportsProfileProperties
            })

            request.updateGroups = updateGroups
            //
            // let request = {...this.updateProfileModel}
            // let {
            //     firstName,
            //     lastName,
            //     height,
            //     weight,
            //     gender,
            //     birthDate,
            //     email
            // } = this.editProfileForm;
            //
            //
            // request.firstName = firstName;
            // request.lastName = lastName;
            // request.height = height;
            // request.weight = weight;
            // request.birthDate = moment(birthDate).format();
            // request.gender = gender;
            // request.email = email;
            //
            // if (!this.organizationPreferences.isMetric) {
            //     height = parseInt(height) * 12 + parseInt(this.editProfileForm.inches)
            // }
            //
            // request.height = height;

            // let {data: response} = await createProfile(request);
            let response = await updateProfile(request);
            // 
            if (!response.isSuccess) {
                this.$store.commit(SET_SNACKBAR, {
                    text: response.errors[0].message,
                    color: 'error',
                    open: true
                });
                this.savingProfile = false;
                return
            }

            const {profile: createdProfile} = response;
            this.editProfileDialogOpen = false;
            this.savingProfile = false;
            this.$store.commit(SET_SNACKBAR, {
                text: 'Profile Successfully Updated',
                color: 'success',
                open: true
            });
            this.init()
        },
        // getRoute(testId) {
        //     return {
        //         path: `/profiles/${this.$route.params.profileId}/test/${testId}`,
        //         props: {
        //             profileTest: this.profile.tests.find(test => test.data.id === testId)
        //         }
        //     }
        // },
        // openTest(test) {
        //     let route = this.getRoute(test.id);
        //     this.$router.push(route)
        // },
        // displayTestDate(date) {
        //     return moment(date).format('MMM D, YYYY')
        // },
        // displayTestDateSubheader(date) {
        //     return moment(date).format('dddd @ h:mm a')
        // },
        // getColor(calories) {
        //     if (calories > 400) return 'red'
        //     else if (calories > 200) return 'orange'
        //     else return 'green'
        // },
        // downloadProfileTest(test) {
        //     this.selectedProfileTest = test;
        //     setTimeout(() => {
        //         this.exportPdf()
        //     }, 200)
        // },

        // async exportPdf() {
        //     this.exportingPdf = true;
        //    
        //
        //     let documentCopy = document.cloneNode(true);
        //     let mainContent = documentCopy.getElementById("app");
        //     let newContent = documentCopy.getElementById("test-pdf")
        //     mainContent.parentNode.replaceChild(newContent, mainContent);
        //
        //     let head = documentCopy.head;
        //     let link = documentCopy.createElement("link");
        //
        //     link.type = "text/css";
        //     link.rel = "stylesheet";
        //     link.href = `${window.location.origin}/pdf.css`;
        //
        //     head.appendChild(link)
        //
        //     console.log(documentCopy.documentElement.innerHTML)
        //
        //     let htmlToSend = "<html>" + documentCopy.documentElement.innerHTML + "</html>";
        //
        //     const headerHtmlString = getProfileTestPdfHeader(moment(this.selectedProfileTest.completedOn).format('M/D/YYYY'));
        //     const footerHtmlString = getProfileTestPdfFooter()
        //    
        //     let request = {
        //         id: this.profile.profileInfo.id,
        //         orgId: 1,
        //         htmLString: htmlToSend,
        //         pdfPassword: this.pdfPassword,
        //         footerHtmlString,
        //         headerHtmlString,
        //     };
        //
        //     let result = await createPdf({
        //         request: request,
        //         firstName: this.firstName,
        //         lastName: this.lastName
        //     });
        //     this.exportingPdf = false;
        // },
        async deleteProfile() {
            this.deletingProfile = true;

            let response = await deleteProfile(this.profile.profileInfo.id);

            if (!response.isSuccess) {
                this.deletingProfile = false;
                return
            }
            this.$store.commit(SET_SNACKBAR, {
                text: 'Profile Successfully Deleted',
                color: 'success',
                open: true
            })
            // 
            this.deletingProfile = false;
            this.deleteProfileDialogOpen = false;
            await this.$router.push('/profiles')
        },
	    testUpdated() {
		    this.init();
	    },
        async retryPdfSync(){
            await retryPdfSync(this.pdfSyncStatus.pdfSyncIntentId);
        },
        async markPdfSyncManual(){
            await updatePdfSyncStatus(this.pdfSyncStatus.pdfSyncIntentId, 11);
            this.init();
        },
        async markPdfSyncDeleted(){
            await updatePdfSyncStatus(this.pdfSyncStatus.pdfSyncIntentId, 6);
            this.init();
        }
    }
}
</script>

<style scoped>
.card-header {
    color: var(--v-dark-base)
}

.table {
    color: var(--v-text-base) !important
}


.table-date {
    display: flex;
    flex-direction: column;
}

.event-banner {
    margin-top: 10px;
    width: 100%;
    font-weight: bold;
    background-color: var(--v-swayOrange-darken1);
    color: white;
    padding: 5px;
    display: flex;
}

.event-banner:hover {
    cursor: pointer
}
</style>