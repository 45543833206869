<template>

    <dialog-form
        :open="open"
        @close="onClose"
        title="Connect to external API"
        @submit="submitPressed"
        :saving="savingNewSession"
        :submitButtonTitle="isAgreeTermsShowing ? (isAgreeToTermsChecked ? 'Agree' : 'Cancel') : 'Submit'"
    >
        <template v-slot:form-content>
            <div v-if="!isAgreeTermsShowing">
                <div class="inline-block-field">
                    <app-select
                            v-model="ehrPartnerId"
                            label="Which EHR/provider are you using for API integration?"
                            :items="ehrPartnerOptions()"
                            item-text="text"
                            item-value="value"
                    />
                </div>
                <div v-if="form.ehrPartnerId == 0" class="inline-block-field">
                    <app-text-field v-model="form.otherApiPartner" label="API Provider"></app-text-field>
                </div>
                <div v-if="isPullSyncApiType() || form.ehrPartnerId == 6" class="inline-block-field">
                    <app-form-date-picker v-model="form.expirationDate" label="Expiration Date (UTC)" readonly></app-form-date-picker>
                </div>
                <div v-if="form.ehrPartnerId == 4" class="inline-block-field">
                    <app-text-field v-model="form.orgExternalId" label="Healthy Roster Provider ID"></app-text-field>
                </div>
                <div v-if="form.ehrPartnerId == 5" class="inline-block-field">
                    <app-text-field v-model="form.orgExternalId" label="Privit Organization ID"></app-text-field>
                </div>
                <div v-if="form.ehrPartnerId == 5" class="inline-block-field">
                    <app-text-field v-model="form.orgUrlPrefix" label="Privit Url Prefix"></app-text-field>
                </div>
                <div v-if="form.ehrPartnerId == 7" class="inline-block-field">
                    <app-text-field v-model="form.orgExternalId" label="SportsWare Online Customer ID"></app-text-field>
                </div>
            </div>
            <div v-else>
                <div>
                    <p>The terms of service below applies to all individuals who use the Sway Medical, Inc. API Key (the “Sway API Key”) to access and download protected Personal Health Information previously collected by your organization from Sway servers.  All other uses are strictly prohibited and could result in civil and criminal penalties.</p>
                    <p>By clicking the “I AGREE” button blow, you acknowledge that you have read and accepted the following terms of service:</p>
                    <ul>
                        <li>I understand and affirm that I am using the Sway API Key with the full permission of my organization and that I am subject to the Terms of the License Agreement my organization signed with Sway Medical, Inc.</li>
                        <li>I understand that I will be accessing Protected Health Information that is private and confidential and that I am only authorized to access information and data that has been collected and stored previously by my organization.</li>
                        <li>I understand that access to this information and data is governed by the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”).</li>
                        <li>I understand that unauthorized or improper use of this system is prohibited and may result in administrative, civil, or criminal penalties and/or other actions.</li>
                        <li>I understand that I am responsible for properly protecting any information that I download using the Sway API Key.</li>
                        <li>I understand that my usage of this system may be monitored, recorded, and is subject to audit and review.</li>
                    </ul>
                    <br />
                    <p>WARNING:  A person who knowingly obtains or discloses individually identifiable health information in violation of the HIPAA Privacy Rule may face a criminal penalty of up to $50,000 and up to one-year imprisonment.  The criminal penalties increase to $100,000 and up to five years imprisonment if the wrongful conduct involves false pretenses, and to $250,000 and up to 10 years imprisonment if the wrongful conduct involves the intent to sell, transfer, or use identifiable health information for commercial advantage, personal gain or malicious harm.</p>
                    <p>If you have any question about the terms of either of these agreements, DO NOT CLICK “I AGREE” below and instead contact Sway or the designated contact in your organization who is responsible for authorizing access to organizational data.</p>
                    <p>I have read and understood the terms above and AGREE to be bound by them.</p>
                </div>

                <div class="inline-block-field">
                    <app-form-checkbox v-model="isAgreeToTermsChecked" label="I AGREE" readonly></app-form-checkbox>
                </div>
            </div>

        </template>

    </dialog-form>

</template>

<script>
import {createExternalIntegration} from "@/services/organization.service";
import moment from "moment";
import 'moment-timezone'
import {SET_SNACKBAR} from "@/store/mutation-types";
import {ehrPartnerOptions} from "@/js/shared/constants";

export default {
    name: "ExternalApiCreateForm",
    props: {
        open: {
            type: Boolean
        },
    },
    data() {
        return {
            savingNewSession: false,
            form: null,
            ehrPartnerId: null,
            isAgreeTermsShowing: false,
            isAgreeToTermsChecked: false
        }
    },
    mounted() {
        this.init()

    },
    watch: {
        ehrPartnerId: function (val) {
            this.init();
            this.form.ehrPartnerId = val;
        }
    },
    methods: {
        ehrPartnerOptions() {
            if (this.$store.state.currentAccount.isSuperAdmin){
                return ehrPartnerOptions
            }
            // filter out Telegra options for now
            return ehrPartnerOptions.filter(x => x.value !== 6);
        },
        allowedDates(e) {
            return !moment(e).isBefore(moment().subtract(1, 'day'));
        },
        async init() {
            let form = {
                expirationDate: null,
                ehrPartnerId: null,
                otherApiPartner: null,
                orgExternalId: null,
                orgUrlPrefix: null
            }
            this.form = form
            this.isAgreeTermsShowing = false
            this.isAgreeToTermsChecked = false
        },
        isPullSyncApiType() {
            return this.form.ehrPartnerId == 0 || this.form.ehrPartnerId == 1
                || this.form.ehrPartnerId == 2 || this.form.ehrPartnerId == 3  || this.form.ehrPartnerId == 8;
        },
        async submitPressed() {
            if (this.form.ehrPartnerId == null)
                return // do nothing
            
            let requireTerms = this.isPullSyncApiType();
            
            if (requireTerms){
                if (!this.isAgreeTermsShowing) {
                    // show terms
                    this.isAgreeTermsShowing = true;
                    this.isAgreeToTermsChecked = false;
                }
                else if (this.isAgreeToTermsChecked) {
                    await this.submitCreateExternalApiKeyForm()
                }
                else {
                    // go back to form
                    this.isAgreeTermsShowing = false;
                    this.isAgreeToTermsChecked = false;
                }
            }
            else {
                await this.submitCreateExternalApiKeyForm()
            }
        },
        async submitCreateExternalApiKeyForm() {
            this.savingNewSession = true;
            let request = {
                model: {
                    organizationId: this.orgId,
                    expirationDate: this.form.expirationDate,
                    ehrPartnerId: this.form.ehrPartnerId,
                    orgExternalId: this.form.orgExternalId,
                    otherApiPartner: this.form.otherApiPartner,
                    orgUrlPrefix: this.form.orgUrlPrefix,
                    termsVersion: this.isPullSyncApiType() ? 1 : null
                }
            }
            
            let response = await createExternalIntegration(request);
            this.savingNewSession = false;

            if (!response.isSuccess) {
                return
            }

            this.$store.commit(SET_SNACKBAR, {
                text: 'Successfully Created',
                color: 'success',
                open: true
            });
            this.savingNewSession = false;
            this.$emit('apiKeyCreated')
            this.$emit('close')
        },
        async onClose(){
            await this.init();
            this.ehrPartnerId = null;
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>